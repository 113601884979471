import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import goutteencre from '../pictures/goutteencre.png';
import goutteencrefondgris from '../pictures/goutteencre-fond-gris.png';
import '../helpers/i18n';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import pictopoids from '../pictures/picto-poids.png';
import pictoref from '../pictures/picto-reference.png';
import pictopourcent from '../pictures/picto-pourcentage.png';
import pictoteinte from '../pictures/picto-teinte.png';
import '../styles/Resultfilter.css';
import imgattention from '../pictures/attention.png';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import HubspotForm from 'react-hubspot-form';
import { Button } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from "@mui/material/styles";
import  { useEffect, useState,useRef } from 'react';
import {  TextField } from '@mui/material'; // Assurez-vous d'importer ces composants depuis le bon emplacement
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { Sync } from '@material-ui/icons';
import ReCAPTCHA from 'react-google-recaptcha';


function Resultfilter ({PrintNomSerie, PrintNomGamme,  PrintNomType, compoPantone, SetCompoPantone, Pantone, setPantone, optUnite, setOptUnite, optPoids, setOptPoids }) {
  const [open, setOpen]  = React.useState(false);
  const handleClickOpen = () => {setOpen(true); };
  const handleClose = () => {setOpen(false); };
const Couleurpantone = Pantone.map((pan) => (pan.hexa))
const refpantone = Pantone.map((pan) => ( pan.refpantone))
const { t } = useTranslation();
const theme2 = useTheme();
const fullScreen = useMediaQuery(theme2.breakpoints.down('md'));
const [description, setDescription] = useState('');
const captchaRef = useRef(null);
const [descriptionValid, setDescriptionValid] = useState(false);
const [error, setError] = useState('');
const [recaptchaToken, setRecaptchaToken] = useState(null);
const handleDescriptionChange = (event) => {
  const descriptionValue = event.target.value;
  setDescription(descriptionValue);
  setDescriptionValid(descriptionValue.trim() !== '');
  
};
const handleSubmit = async () => {
  try {
    const response = await axios.post('/api/postdata/read/captcha', { recaptchaResponse: recaptchaToken });
    if (response.data === 'reCAPTCHA validé avec succès') {
      sendemail();
      setOpen(false);
    } else {
      setError('Problème envoi, Veuillez valider le reCAPTCHA avant d\'envoyer le formulaire.');
    }
  } catch (error) {

    setError('Veuillez valider le reCAPTCHA avant d\'envoyer le formulaire.');
  }
};

  const handleRecaptchaChange = (token) => {
    // Le reCAPTCHA a été validé, obtenez le token reCAPTCHA
    setRecaptchaToken(token);
  };
  const sendemail = async () => {
    try {
      let reference = refpantone[0]
      // Vérification des informations saisies par l'utilisateurPrintNomGamme
      const response =  await axios.post('/api/postdata/read/sendalert', { PrintNomSerie, PrintNomType, PrintNomGamme,reference,description });
      // Si la réponse est réussie, affiche un message à l'utilisateur et active l'envoi du code de confirmation
      setIsCodeSent(true);
      } 
    catch (error) {
      setError("Une erreur s'est produite lors de l'envoi du mail");
      }
    }
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  boxShadow: '3px 3px 3px 0px rgba(0, 0, 0, 0.28)',
  borderBottom:'0px',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#FFFFFF', 
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    fontFamily: 'Work Sans',
    fontSize: '17px',
    fontWeight:'700',
  },
  [`&.${tableCellClasses.body}`]: {
    padding: '8px',
    fontFamily: 'Work Sans',
    fontSize: '17px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  
    backgroundColor: '#FFFFFF',
  '&:nth-of-type(even)': {
    backgroundColor: '#ffffff', 
  },
  '&:nth-of-type(odd)': {
    backgroundColor: '#F4F5F7',  
  },
    '&:last-child td': {
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
      boxShadow: '3px 3px 3px 0px rgba(0, 0, 0, 0.28)',
    },
}));
    return  (
      <div style={{ position: 'relative', marginTop: '30px' }}>
      <Grid container spacing={2}>
      <Grid alignItems="center" item xs={12} >
      <TableContainer sx={{boxShadow:'none',overflowX: 'visible',}} component={Paper}>
        <Table sx={{ minWidth: 200, borderCollapse: 'separate', borderSpacing: '10px 0px',}} aria-label="simple table">
          <TableHead key="Entete" sx={{overflow: 'hidden',backgroundcolor: '#ffffff' }}>
            <StyledTableRow sx={{overflow: 'hidden',}}>
              <StyledTableCell align="center"style={{ width: '25%',  padding: '8px', '@media screen and (maxWidth: 800px)': { width: 'auto', minWidth: 'auto' } }} >
                <Grid container justifyContent="center"  alignItems="center" spacing={1}>
                  <Grid item>
                    <div style={{ width: '32px', height: '32px', backgroundImage: `url(${pictoteinte})`}}></div>
                  </Grid>
                  <Grid sx={{'@media screen and (maxWidth: 800px)': {fontSize:'13px'} }}  item>
                    {t('result.teinte')}
                  </Grid>
                </Grid>
              </StyledTableCell>        
              <StyledTableCell align="center"  style={{width: '25%', padding: '8px', '@media screen and (maxWidth: 800px)': { width: 'auto', minWidth: 'auto' }}}>

                <Grid container justifyContent="center"  alignItems="center" spacing={1}>
                  <Grid item>
                    <div style={{ width: '32px', height: '32px', backgroundImage: `url(${pictopourcent})`}}></div>
                  </Grid>
                  <Grid  sx={{ '@media screen and (maxWidth: 800px)': {fontSize:'13px'} }} item>
                    {t('result.pourcentage')}
                  </Grid>
                </Grid>
              </StyledTableCell>
              <StyledTableCell align="center" style={{width: '25%', padding: '8px' , '@media screen and (maxWidth: 800px)': { width: 'auto', minWidth: 'auto' }}}>
                <Grid container justifyContent="center"  alignItems="center" spacing={1}>
                  <Grid  item>
                    <div style={{ width: '32px', height: '32px', backgroundImage: `url(${pictopoids})` }}></div>
                  </Grid>
                  <Grid sx={{  '@media screen and (maxWidth: 800px)': {fontSize:'13px'} }}  item>
                   {t('result.quantite')}
                  </Grid>
                </Grid>
                </StyledTableCell>
                <StyledTableCell align="center"style={{ width: '25%',padding: '8px' , '@media screen and (maxWidth: 800px)': { width: 'auto', minWidth: 'auto' }}}>
                <Grid container justifyContent="center"   alignItems="center" spacing={1}>
                  <Grid item>
                    <div style={{ width: '32px', height: '32px', backgroundImage: `url(${pictoref})` }}></div>
                  </Grid>
                  <Grid sx={{  '@media screen and (maxWidth: 800px)': {fontSize:'13px'} }} item>
                    {t('result.reference')}
                  </Grid>
                </Grid>
                </StyledTableCell>
            </StyledTableRow>
          </TableHead>
            <TableBody >
                   { compoPantone.map((compo,index) => (
                 
                 <StyledTableRow key={`${compo.nom}-${index}`}>
  <StyledTableCell align="center">
    <Grid container alignItems="center" spacing={1} sx={{'@media screen and (maxWidth: 800px)': {fontSize:'13px' ,justifyContent:"center",  alignItems:"center"},}} >
      {compo.hexa && compo.hexa !== "" && (
        <Grid item>
          <div style={{ width: '30px', height: '43px', backgroundColor: `${compo.hexa}`}}>
            <img src={index % 2 === 0 ? goutteencrefondgris : goutteencre} style={{ width : '30px'}}  />
          </div>
        </Grid>
      )}
      <Grid item>
        {t('langue') === 'FR' ? compo.nom : compo.nom_en}
      </Grid>
    </Grid>
  </StyledTableCell>
  <StyledTableCell height='58px' align="center" >{compo.weigt} </StyledTableCell>
  <StyledTableCell height='58px' align="center"> {compo.weigt !== "" && compo.weigt !== undefined ? (
    <span>{Math.round(((compo.weigt / 100) * optPoids) * 1000) / 1000} {optUnite}</span>) : (<span>&nbsp;</span>)}
  </StyledTableCell>
                  <StyledTableCell height='58px' align="center">{compo.ref1l}</StyledTableCell>
                  </StyledTableRow>
                  
              ))}
            </TableBody>
          </Table> 
        </TableContainer>

        </Grid>
        {compoPantone.some(item => item !== "") && (
        <Grid item xs={12} style={{   }}>
          <Stack  align="right" spacing={1} direction="row" >
            <Button title={t('contact')}  style={{  textTransform: 'none',  borderRadius:'10px',  padding: '5px 10px', marginLeft:'20px',backgroundColor: "#FF1233" }} variant="contained"onClick={handleClickOpen} > 
              <img src={imgattention} style={{ width : '30px', marginRight: '10px'}}  /> {t('erreurformule.erreurboutton')}
            </Button>
            
            <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
  <DialogTitle id="responsive-dialog-title" style={{ color: 'Red' }}>{t('signalerreur')}</DialogTitle>
  <DialogContent>
    <Typography variant="body1" style={{ marginBottom: '8px' }}>{t('erreurformule.information')} </Typography>
    <Typography variant="body2">{PrintNomType}</Typography>
    <Typography variant="body2">{PrintNomSerie}</Typography>
    <Typography variant="body2">{PrintNomGamme}</Typography>
    <Typography variant="body2"  style={{ marginBottom: '8px' }}>{t('erreurformule.reference')} {refpantone}</Typography>
    <Typography variant="body1" >{t('erreurformule.descriptioninfo')}</Typography>
    <TextField
      autoFocus
      margin="dense"
      label={t('erreurformule.description')}
      variant="outlined"
      multiline
      rows={4}
      fullWidth
      value={description}
      onChange={handleDescriptionChange}
      style={{ marginTop: '16px' }}
    />
        {error && (
      <Typography variant="body2" style={{ marginTop: '8px', color: 'red' }}>{error}</Typography>
    )}
      <ReCAPTCHA ref={captchaRef} sitekey="6Lcsat0pAAAAAC_w5jrXPAK98isxyndOtUz4UOQa" onChange={handleRecaptchaChange}/>


  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose} color="secondary">
    {t('erreurformule.erreurbouttonannuler')}
    </Button>
    
<Button onClick={handleSubmit} color="primary" disabled={!descriptionValid}>
{t('erreurformule.erreurbouttonenvoyer')}
</Button>
  </DialogActions>
</Dialog>
          </Stack>
        </Grid>  
        )}

        </Grid>
    </div>
    ) 
}
export default Resultfilter

 







  
