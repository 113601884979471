import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@material-ui/core/Grid';
import logo from '../pictures/logo-cms.png';
import Selectfilter from './Selectfilter.js';
import Resultfilter from './Resultfilter.js';
import  { useEffect, useState } from 'react';
import  { forwardRef, useRef } from "react";
import  { PrintContextConsumer } from "react-to-print";
import '../helpers/i18n';
import { useTranslation } from "react-i18next";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Link from '@mui/material/Link';
import logotiflex from '../pictures/logo.png';
import Backgroundheader from '../pictures/banniere.png';
import Backgroundfooter from '../pictures/footer.png';
import { Button, CssBaseline, GlobalStyles } from "@mui/material";
import { ThemeProvider, createTheme,useTheme } from "@mui/material/styles";
import '../styles/Dashboard.css';
import '../helpers/i18n.js';
import imgfr from '../pictures/fr_FR.png';
import imgen from '../pictures/en_GB.png';
import imgmail from '../pictures/email-blanc.png';
import imgcatalogue from '../pictures/magazine-blanc.png';
import imginternet from '../pictures/internet-blanc.png';
import imgconditiongeneral from '../pictures/conditions-generales.png';
import imginformation from '../pictures/information.png';
import pictoweb from '../pictures/web.png';
import pictolocalisation from '../pictures/localisation.png';
import pictotelephone from '../pictures/telephone.png';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import Hidden from '@mui/material/Hidden';
import HubspotForm from 'react-hubspot-form';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import goutteencre from '../pictures/goutteencre.png';
import goutteencrefondgris from '../pictures/goutteencre-fond-gris.png';
import pictopoids from '../pictures/picto-poids.png';
import pictoref from '../pictures/picto-reference.png';
import pictopourcent from '../pictures/picto-pourcentage.png';
import pictoteinte from '../pictures/picto-teinte.png';

const workSans = require('@fontsource/work-sans');
const drawerWidth = 371;
function ResponsiveDrawer(props) {
  const [open, setOpen]  = React.useState(false);
  const theme2 = useTheme();
  const fullScreen = useMediaQuery(theme2.breakpoints.down('md'));
  const handleClickOpen = () => {setOpen(true); };
  const handleClose = () => {setOpen(false); };
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {setMobileOpen(!mobileOpen);};
  const { i18n } = useTranslation();
  const changeLanguage = async (lng) => {await i18n.changeLanguage(lng);};
  const [ compoPantone, setCompoPantone ]= useState(["","","",]);
  const [ Pantone, setPantone ]= useState([]);
  const [ optPoids, setOptPoids ]= useState([1]);
  const [ optUnite, setOptUnite ]= useState(["kg"]);
  const [ refprops, setRefprops ]= useState([]);
  const [PrintNomSerie, setPrintNomSerie ]= useState([""]);
  const [PrintNomGamme,setPrintNomGamme  ]= useState([""]);
  const [PrintNomType,setPrintNomType ]= useState([""]);
  const  ref= useRef();
  const Couleurpantone = Pantone.map((pan) => (pan.hexa));
  const refpantone = Pantone.map((pan) => ( pan.refpantone));
  const { t } = useTranslation();

  const ComponentToPrint = forwardRef((props, ref) => {
    return <div  style={{ marginLeft:'auto',marginRight:'auto', width: '90%',}} ref={ref}>   
       <Grid container justifyContent="center"   alignItems="center" spacing={1}>
               <Grid item>
               <img src={logotiflex} alt="Logo"  height="72"/>
               
                  <img src={logo} alt="Logo" width="70" height="72"/>
               </Grid>
                <Grid sx={{ display: { xs: 'none',md: 'block' }}} item>
                  <Typography   variant="h4" component="h1" wrap="true">
             Color Matching System<br></br>
                  </Typography>
               </Grid>
        </Grid>
        <Typography  variant="pantone" wrap="true" sx={{ marginLeft: '16px'}}>{PrintNomSerie}</Typography><br></br>
        <Typography  variant="pantone" wrap="true" sx={{ marginLeft: '16px'}}>{PrintNomType}</Typography><br></br>
        <Typography  variant="pantone" wrap="true" sx={{ marginLeft: '16px'}}>{PrintNomGamme}</Typography><br></br>

        <Grid container  alignItems="center" spacing={1}>
            <Grid item>
                  <Typography  variant="pantone" wrap="true" sx={{ marginLeft: '16px'}}>{t('result.pantone')}® {refpantone}</Typography> 
            </Grid>
            <Grid item>
                <div style={{ width: '30px', height: '43px', backgroundColor: `${Couleurpantone}`}}> <img src={goutteencre } style={{ width : '30px'}}  /> </div>
            </Grid>
        </Grid>
              <br></br>
          <TableContainer sx={{boxShadow:'none',overflowX: 'visible',}} component={Paper}>
          <Table sx={{ minWidth: 200, borderSpacing: '10px 0px',}} aria-label="simple table">
            <TableHead key="Entete" sx={{overflow: 'hidden',backgroundcolor: '#ffffff' }}>
              <TableRow sx={{overflow: 'hidden',}}>
              <TableCell align="center"  >
              <Grid container justifyContent="center"  alignItems="center" spacing={1}>
                <Grid item>
                <div style={{ width: '32px', height: '32px', backgroundImage: `url(${pictoteinte})`}}></div>
               </Grid>
                <Grid sx={{ display: { xs: 'none',md: 'block' }}}  item>
                 {t('result.teinte')}
               </Grid>
                </Grid>
                </TableCell>        
                <TableCell align="center">
                <Grid container justifyContent="center"  alignItems="center" spacing={1}>
                  <Grid   item>
                <div style={{ width: '32px', height: '32px', backgroundImage: `url(${pictopourcent})`}}></div>
               </Grid>
                <Grid  sx={{ display: { xs: 'none',md: 'block' }}} item>
                {t('result.pourcentage')}
               </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="center">
                <Grid container justifyContent="center"  alignItems="center" spacing={1}>
                  <Grid  item>
                <div style={{ width: '32px', height: '32px', backgroundImage: `url(${pictopoids})` }}></div>
               </Grid>
                <Grid sx={{ display: { xs: 'none',md: 'block' }}}  item>
               
                {t('result.quantite')}
               </Grid>
                  </Grid>
                  </TableCell>
                <TableCell align="center">
                <Grid container justifyContent="center"   alignItems="center" spacing={1}>
                  <Grid item>
                <div style={{ width: '32px', height: '32px', backgroundImage: `url(${pictoref})` }}></div>
               </Grid>
                <Grid sx={{ display: { xs: 'none',md: 'block' }}} item>
                {t('result.reference')}
               </Grid>
                  </Grid>
                  </TableCell>
                </TableRow>
            </TableHead>
            <TableBody >{ compoPantone.map((compo,index) => (
                 <TableRow key={`${compo.nom}-${index}`}> 
                 <TableCell align="center">
                 <Grid container  alignItems="center" spacing={1}>
                  <Grid item>
                  <div style={{ width: '30px', height: '43px', backgroundColor: `${compo.hexa}`}}> <img src={goutteencre } style={{ width : '30px'}}  /> </div>
               </Grid>
                <Grid item>
                {t('langue') === 'FR' ? compo.nom : compo.nom_en}
               </Grid>
                  </Grid>
                 </TableCell>
                  <TableCell align="center">{compo.weigt} </TableCell>
                  <TableCell align="center">{Math.round( ( (compo.weigt /100)*optPoids) *1000)/1000 }  {optUnite} </TableCell>
                  <TableCell align="center">{compo.ref1l}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table> 
        </TableContainer>
        <br></br>
        <Typography variant="h6"> {t('condigenrale.titrecond')}</Typography> <br></br>
        <Typography variant="body2">{t('condigenrale.condtion')}</Typography>
        <Grid container justifyContent="center"  alignItems="center" >
          <Grid item>
          <Typography variant="body2">{'© TIFLEX 2006 - '} {new Date().getFullYear()}{'. '}{t('droitreserve')}</Typography>
          </Grid>
        </Grid>
        <style>
        {`
          @media print {
            body {
              transform: scale(0.81);
            }
            @page {
              size: portrait; /* Définit l'orientation de la page en mode portrait */
              background: white; 
            }
          }
        `}
      </style>
 </div>;
});
  const theme = createTheme({
    typography: {
      fontFamily: 'Work Sans',
      color:'#15122A',
      variantcopyright: {
        fontFamily: 'Work Sans',
        fontSize: '14px',
        color:'#ffffff',
      },
      titlecms1: {
        fontFamily: 'Work Sans',
        fontSize: '40px',
        color:'#ffffff',
        fontWeight:700,
      },
      titlecmsmobile: {
        fontFamily: 'Work Sans',
        fontSize: '15px',
        color:'#ffffff',
        fontWeight:700,
      },
      titlecms2: {
        fontFamily: 'Work Sans',
        fontSize: '32px',
        color:'#ffffff',
        fontWeight:700,
      },
      titlecms3: { 
        fontFamily: 'Work Sans',
        fontSize: '38px',
        color:'#ffffff',
        fontWeight:700,
      },
      bodycontact: {
        fontFamily: 'Work Sans',
        fontSize: '14px',
        color:'#ffffff',
        lineHeight: 1.4,
      } ,
      titlecontact: {
        fontFamily: 'Work Sans',
        fontWeight:700,
        fontSize: '17px',
        color:'#ffffff',
        lineHeight: 1.4,
      } ,
      bodyapp: {
       fontFamily: 'Work Sans',
       fontSize: '17px',
       color:'#15122A',
      } ,
      pantone: {
        fontFamily: 'Helvetica',
        fontSize: '16px',
        color:'#15122A',
        fontWeight: 'bold',
      } ,
      pantoneimp: {
        fontFamily: 'Helvetica',
        fontSize: '20px',
        color:'#15122A',
        fontWeight: 'bold',
      } ,
    },
    palette: {
      primary: {
        light: '#252525',
        main: '#252525',
        dark: '#252525',
        contrastText: '#fff',
        backgroundColor: '#252525',
        fontFamily: 'Work Sans',
      },
      secondary: {
        light: '#252525',
        main: '#252525',
        dark: '#252525',
        contrastText: '#252525',
        fontFamily: 'Work Sans',
      },
    },
  }
  );
  const drawer = (
    <div>
      <Toolbar  variant="dense"sx={{ maxHeight: 48, height: 48,}}     >
      </Toolbar >
      <Box sx={{ maxWidth: 366, marginLeft:'53px',}}>
        <List>
          <Typography variant="bodyapp">
            <Link color="inherit" href="https://www.tiflex.com/">
              <img src={logotiflex} href="https://www.tiflex.com/" alt="Logo" />
            </Link>
          </Typography>
          <Selectfilter setMobileOpen={setMobileOpen}  mobileOpen = {mobileOpen} PrintNomGamme= {PrintNomGamme} setPrintNomGamme= {setPrintNomGamme} PrintNomType= {PrintNomType} setPrintNomType= {setPrintNomType} PrintNomSerie= {PrintNomSerie} setPrintNomSerie= {setPrintNomSerie} refprops={ref} compoPantone={compoPantone} setCompoPantone={setCompoPantone} Pantone={Pantone} setPantone={setPantone}  optPoids={optPoids} setOptPoids={setOptPoids} optUnite={optUnite} setOptUnite={setOptUnite} />
        </List>
        </Box>
    </div>
  );
  const container = window !== undefined ? () => window().document.body : undefined;
  return (
  <ThemeProvider theme = { theme }>
    <Box sx={{ display: 'flex' , minHeight: '100vh', position: 'relative'}}>
      <CssBaseline />
      <GlobalStyles styles={{body: { backgroundColor: '#ffffff',},}}/>
      <AppBar position="fixed"sx={{  maxHeight: 48, zIndex: (theme) => theme.zIndex.drawer + 1,}}>
          <Toolbar variant="dense" sx={{ maxHeight: 48, height: 48}}>
            <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { lg: 'none' } }} >
              <MenuIcon />
            </IconButton>
              <Typography  sx={{ display: { xs: 'none', lg: 'block' }}} variant="titlecontact"  wrap="true" >{t('contacttitre')}</Typography>
              <IconButton  sx={{display: { xl:'none',lg: 'none',md:'none', sx: 'block'}, p: 0 }}>
                <Avatar sx={{display: { xl:'none',lg: 'none',md:'none', sx: 'block' } }}  alt="Color Matching System" src={logo} />
              </IconButton>
              <Typography  sx={{ display: {  xl:'none',lg: 'none',md:'none', sx: 'block'} }}  variant="titlecmsmobile" component="h1" wrap="true" >Color Matching System</Typography>

              <Box sx={{ flexGrow: 1 }} />
               <Box >
                  <Button style={{minWidth:'48px', width : '30px'}}><img src={imgfr} style={{ width : '30px'}} onClick={() => changeLanguage('fr')} /></Button>
                  <Button style={{minWidth:'48px', width : '30px'}}><img src={imgen} style={{ width : '30px'}} onClick={() => changeLanguage('en')} /></Button>
                </Box>
          </Toolbar>
        </AppBar>
      <Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 },}}>
        <Drawer container={container} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} ModalProps={{ keepMounted: true, }}
          sx={{ display: { xs: 'block', lg: 'none' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, boxShadow:'1px 0px 8px rgba(0, 0, 0, 0.6)'},}} >
          {drawer}
        </Drawer>
        <Drawer variant="permanent" sx={{ display: { xs: 'none', lg: 'block' },'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth,boxShadow:'1px 0px 8px rgba(0, 0, 0, 0.6)'} }} open>
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{position: 'absolute',top: 0,left: 0,width: '100%',height: 324,backgroundImage: `url(${Backgroundheader})`,backgroundSize: 'cover',zIndex: -1,}}/>
      <Toolbar  variant="dense" sx={{ maxHeight: 48, height: 48}}/>
      <Box component="div" sx={{ flex: 1, paddingTop:1,  p: 2 }}>
        <Grid container spacing={2}  >
          <Grid item xs={4} >
            <Box display="flex" justifyContent="center" sx={{ display: { xs: 'none',md: 'block' }}} >
              <Grid container justifyContent="center"   alignItems="center" spacing={1}>
                <Grid item>
                  <img src={logo} alt="Logo" />
               </Grid>
                <Grid sx={{ display: { xs: 'none',md: 'block' }}} item>
                  <Typography   variant="titlecms1" component="h1" wrap="true">Color <br></br></Typography>
                  <Typography  variant="titlecms2" component="h1" wrap="true" >Matching  <br></br></Typography>
                  <Typography  variant="titlecms3" component="h1" wrap="true" >System</Typography>
               </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center">
              <div style={{ width: '163px', height: '214px', backgroundColor: 'white', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)', marginLeft: 'auto', marginRight: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                <div style={{ width: '143px', height: '145px', margin: '10px', backgroundColor: `${Couleurpantone}` }}></div>
                <div style={{ color: 'black', textAlign: 'center' }}>
                  <Typography  variant="pantone" wrap="true" sx={{ marginLeft: '16px'}}>{t('result.pantone')}® {refpantone}</Typography>
                </div>
              </div>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center" sx={{ display: { xs: 'none',md: 'block' } }}>
              <Stack  align="right" spacing={1} direction="row">
                <Button title="Notre catalogue" href={t('boutton.catalogue')} style={{  width:'80px',height:'80px', borderRadius:'50%',backgroundColor: "#FF1233" }} variant="contained" >
                  <img src={imgcatalogue} style={{ width : '45px'}}  />    
                </Button>
                <Button title={t('contact')}  style={{  width:'80px',height:'80px', borderRadius:'50%',backgroundColor: "#FF1233" }} variant="contained"onClick={handleClickOpen} > 
                  <img src={imgmail} style={{ width : '45px'}}  />       
                </Button>
                <Button title={t('boutton.infositeweb')} href={t('boutton.siteweb')}  style={{  width:'80px',height:'80px', borderRadius:'50%',backgroundColor: "#FF1233" }} variant="contained" >
                  <img src={imginternet} style={{ width : '45px'}}  />        
                </Button>
                <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" >
                    <DialogTitle id="responsive-dialog-title">{t('contact')}</DialogTitle>
                    <DialogContent>   
                    <HubspotForm portalId='4506903' formId='6d4752f1-cce5-41ec-9ccc-7f72e8e98c60' onSubmit={() => console.log('Submit!')} onReady={(form) => console.log('Form ready!')}
                      loading={<div>Loading...</div>}/>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} autoFocus>Fermer</Button>
                  </DialogActions>
                </Dialog>
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ p:3, '@media screen and (max-width: 800px)': {marginLeft: '-35px', marginRight: '-35px'} }}>
          <Grid container spacing={2}  >
            <Grid item xs={12} >
              <div style={{ display: "none" }} > 
                <ComponentToPrint ref={ref} /> 
              </div>
              <Resultfilter PrintNomSerie ={PrintNomSerie} PrintNomGamme ={PrintNomGamme } PrintNomType={PrintNomType} refpantone={refpantone} compoPantone= {compoPantone} setCompoPantone={setCompoPantone} Pantone={Pantone} setPantone={setPantone} optPoids={optPoids} setOptPoids={setOptPoids} optUnite={optUnite} setOptUnite={setOptUnite} />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ p:3}}>
          <Grid container spacing={2}   >
            <Grid item  xs={12} lg={6}  >
              <Accordion sx={{backgroundColor: "#F4F5F7"}} >
                <AccordionSummary  aria-controls="panel1a-content" id="panel1a-header" >
                  <ListItemIcon> <img src={imginformation } style={{ width : '30px'}} /></ListItemIcon>
                      <Typography variant="bodyapp">{t('information.titreinfo')}</Typography>
                      <ExpandMoreIcon />
                </AccordionSummary>
                <AccordionDetails >
                  <Typography paragraph variant="bodyapp">{t('information.info')}</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} lg={6}  >
              <Accordion sx={{backgroundColor: "#F4F5F7"}}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                  <ListItemIcon><img src={imgconditiongeneral } style={{ width : '30px'}} /></ListItemIcon>
                  <Typography variant="bodyapp"> {t('condigenrale.titrecond')}</Typography>
                  <ExpandMoreIcon />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="bodyapp">{t('condigenrale.condtion')}</Typography>
                </AccordionDetails>
              </Accordion>   
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box component="footer"
        sx={{left: 0, position: 'relative', backgroundPosition: 'right', backgroundImage: `url(${Backgroundfooter})`,
          backgroundSize: 'cover',paddingTop: '60px',width: '100%', height: '240px',display: 'flex',flexDirection: 'column', alignItems: 'flex-end', color: '#ffffff',
          '@media screen and (max-width: 800px)': {paddingTop: '5px', backgroundImage: 'none',background: 'linear-gradient(to right, #FF1233, #BA0C2F)',height: '281px', }, }} >
         <Box sx={{ maxWidth: 780, marginRight: '30px','@media screen and (max-width: 800px)': {Width: '100%', marginRight: '20px',marginLeft: '20px'},}}>
            <Box sx={{ borderBottom: '1px solid white',paddingBottom: '5px', maxWidth: '740px' ,'@media screen and (max-width: 800px)': { textAlign: 'center', alignItems: 'center',justifyContent: 'center',maxWidth: '800px', },}}>
              <Typography variant="h6"> {t('contact')}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '5px' ,   '@media screen and (max-width: 800px)': { borderRight:'none',flexDirection: 'column', alignItems: 'center',justifyContent: 'center',maxWidth: '800px', },}}>
              <Box  sx={{ borderRight: '1px solid white', paddingRight: '10px',marginRight: '10px',display: 'flex', alignItems: 'center',justifyContent: 'center','@media screen and (max-width: 800px)': {borderRight:'none', alignItems: 'center',justifyContent: 'center',maxWidth: '800px', },}}>
                <img src={pictoweb} alt="Web" style={{ height: '15px', marginRight: '5px' }} />
                <Typography variant="body2">www.tiflex.com</Typography>
              </Box>
              <Box
                sx={{ borderRight: '1px solid white', paddingRight: '10px',marginRight: '10px',display: 'flex', alignItems: 'center',justifyContent: 'center','@media screen and (max-width: 800px)': {borderRight:'none', alignItems: 'center',justifyContent: 'center',maxWidth: '800px', },}}>
                <img src={pictotelephone} alt="Téléphone" style={{ height: '15px', marginRight: '5px' }} />
                <Typography variant="body2">
                  France: +33 (0) 4 74 37 33 83<br />
                  International: +33 (0) 4 74 37 33 65 </Typography>
              </Box>
              <Box sx={{paddingTop: '5px', paddingRight: '10px', marginRight: '10px', maxWidth: '340px', display: 'flex', alignItems: 'center',justifyContent: 'center','@media screen and (max-width: 800px)': {borderRight:'none', alignItems: 'center',justifyContent: 'center' },}}>
                <img src={pictolocalisation} alt="Localisation" style={{ height: '15px', marginRight: '5px' ,  }} />
                <Typography variant="body2"> TIFLEX - 10 avenue de la 1ère armée Française Rhin-Danube, 01450 PONCIN - FRANCE </Typography>
              </Box>
            </Box>
            <Box sx={{ paddingTop: '8px', maxWidth: '740px',  '@media screen and (max-width: 800px)': {textAlign: 'center',alignItems: 'center',justifyContent: 'center',maxWidth: '800px','@media screen and (max-width: 800px)': {alignItems: 'center',justifyContent: 'center' } }, }}>
              <Typography variant="body2">{t('noncontractuelle')} </Typography>
              <Typography variant="body2">{'© TIFLEX 2006 - '} {new Date().getFullYear()}{'. '}{t('droitreserve')}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </ThemeProvider>
  );
}
ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};
export default ResponsiveDrawer;
