import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // <= new
import Backend from "i18next-http-backend";
import translationEN from "../translation/en.json";
import translationFR from "../translation/fr.json";


const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  }
};
i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)


.init({
  resources,
  fallbackLng: "fr",
  debug: true,
  lng: "fr",
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }
});

export default i18n;
