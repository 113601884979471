import React from 'react';
import  { useEffect, useState } from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import '../helpers/i18n';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import '../styles/Selectfilter.css';
import Selectreact from 'react-select';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from "react-to-print";
import  { forwardRef, useRef } from "react";
import  { PrintContextConsumer } from "react-to-print";
import Button from '@mui/material/Button';

function Selectfilter ({mobileOpen, setMobileOpen, refprops,compoPantone, setCompoPantone, Pantone, setPantone, optUnite, setOptUnite, optPoids, setOptPoids,PrintNomSerie,setPrintNomSerie, PrintNomGamme,setPrintNomGamme, PrintNomType,setPrintNomType}) {

  const [ selectOptType, setSelectOptType ]= useState([]);
  const [ selectOptGamme, setSelectOptgamme] = useState([]);
  const [selectOptSerie, setSelectOptSerie]= useState([]);
  const [ selectOptPantone, setSelectOptPantone ]= useState([]);
  const [idtype, setIdType]= useState(0);
  const [NomSerie, setNomSerie]= useState([""]);
  const [idGamme, setIdGamme]= useState(0);
  const [idSerie, setIdSerie]= useState(0);
  const [idPantone, setidPantone]= useState(0);
  const [hexaPantone, setHexPantone]= useState([""]);
  const [refPantone, setRefPantone]= useState([0]);
  const [init, setInit]= useState(0);
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // État pour contrôler l'ouverture du menu
  
  useEffect(() => {  getDataType();  },  [t('langue')]);
  
  const getDataType = async () => {
    const { data } = await axios.get('/api/postdata/read/listetype');
    const options = data.map(d => ({"value": d.id,"label": t('langue') === 'FR' ? d.nom : d.nom_en }));
    setSelectOptType(options);
  };

  const getDatalisteSerie = async (idtype) => {
    const { data } = await axios.get('/api/postdata/read/listeserie/'+idtype);
    const options = data.map(d => ({"value": d.id, "label" : d.nom }));
    setSelectOptSerie(options)
  };
  
  const getDataGamme = async (nomserie) => {
    const { data } = await axios.get('/api/postdata/read/getgamme/'+ nomserie);
    const options = data.map(d => ({"value": d.id,"label" : d.nom}));
    if(options !="") {
      setShowSelect(true);
      setIsDisabledPantone(true);
    }
    else
    {
      setShowSelect(false);
      setIdGamme(0);
    }
    setSelectOptgamme(options);
  };

const getreference= async  (idtype,nomserie,idgamme) => {
  const { data } = await axios.get('/api/postdata/read/getreference/'+idtype+'/'+nomserie+'/'+idgamme );
  const options = data.map(d => ({"value": d.id,"label" : d.reference}));
  setSelectOptPantone(options)
};
const gethexareference = async  (reference) => {
  const { data } = await axios.get('/api/postdata/read/hexareference/'+ reference);
  const options = data.map(d => ({"hexa": d.hexa,"refpantone" : d.reference}));
  setPantone(options);
};
const getCompoNuancier = async  (idtype,nomserie,idgamme,reference) => {
  const res  = await axios.get('/api/postdata/read/getcomponuancier/' + idtype  + '/' + nomserie + '/' + idgamme + '/' + reference);
  const data = res.data
  setCompoPantone(data.map(d => ({ "hexa": d.couleurhexa,"nom": d.couleur,"nom_en": d.nom_en,"weigt": d.weigt,"ref1l": d.ref1l,})));
};

const handleChangeType = (eventtype) => {
    setPrintNomGamme("");
  // Quand on a un evenement sur le type, on reintitialise tout
    resetserie();
    resetgamme();
    resetpantone();
    setIsDisabledGamme(true);
    setIsDisabledPantone(true);
    setIsDisabledSerie(false); 
    setNomSerie("");
    setInit(0);
    setIdGamme(0);
    setIdSerie(0);
    setHexPantone(0);
    setRefPantone("");
    setHexPantone("#FFFFFF");
    setCompoPantone(["","",""]);
    setPrintNomType(t('filter.type') +" : "+eventtype.label);
    getDatalisteSerie(eventtype.value);
    setIdType(eventtype.value);
};

const handleChangeSerie=  (eventserie) => {
  setPrintNomGamme("");
    resetgamme();
    setValueserie(eventserie);  
    setIsDisabledGamme(false);
    setIdSerie(eventserie.value);
    setNomSerie(eventserie.label);
    getDataGamme(eventserie.label)
    setPrintNomSerie(t('filter.serie') +" : "+ eventserie.label);
    if (init == 0)
    {
        if(idGamme!=0)
        {}
        else
        { 
          setIsDisabledPantone(false);
          getreference(idtype,eventserie.label,idGamme);
         // console.log("Type: " + idtype+ " Serie: "+ eventserie.label+ " Gamme: "+idGamme + " Pantone: " +refPantone);
        }
    }
    else {
      if(idGamme!=0)
      {
        setCompoPantone(["","",""]);
        setRefPantone("");
        setIsDisabledPantone(true);

      }
      else
      { 
          getreference(idtype,eventserie.label,idGamme); 
          //console.log("Type: " + idtype+ " Serie: "+ eventserie.label+ " Gamme: "+idGamme + " Pantone: " +refPantone);
       }
    }
};
const handleChangeGamme =  (eventgamme) => {
  setCompoPantone(["","",""]);
  setRefPantone("");
  setIsDisabledPantone(true);

  setValuegamme(eventgamme);
  setPrintNomGamme(t('filter.gamme') +" : "+eventgamme.label);
 
  resetpantone();
  if (init == 0)
    {
      setIsDisabledPantone(false);
      getreference(idtype,NomSerie,eventgamme.value); 
     // console.log("Type: " + idtype+ " Serie: "+ NomSerie+ " Gamme: "+eventgamme.value + " Pantone: " +refPantone);
      setIdGamme(eventgamme.value);
    }
  else
    {
      setIsDisabledPantone(false);
      setIdGamme(eventgamme.value);
      getreference(idtype,NomSerie,eventgamme.value); 
     // console.log("Type: " + idtype+ " Serie: "+ NomSerie+ " Gamme: "+eventgamme.value + " Pantone: " +refPantone);
    }
};
const handleChangePantone = (eventpantone) => {

    
    setValuepantone(eventpantone);
    setidPantone(eventpantone.value);
    gethexareference(eventpantone.label);
    setHexPantone(eventpantone.value);
    setRefPantone(eventpantone.label);
    getCompoNuancier(idtype,NomSerie,idGamme,eventpantone.label);
    //console.log("Type: " + idtype+ " Serie: "+ NomSerie+ " Gamme: "+idGamme + " Pantone: " +eventpantone.label);
    setInit(1);
    setMobileOpen(false)
};
const [isDisabledSerie, setIsDisabledSerie] = React.useState(true);
const [isDisabledGamme, setIsDisabledGamme] = React.useState(true);
const [isDisabledPantone, setIsDisabledPantone] = React.useState(true);
const [showSelect, setShowSelect] = useState(false);
const [valueserie, setValueserie] = useState("");
const [valuegamme, setValuegamme] = useState("");
const [valuepantone, setValuepantone] = useState("");
const resetserie = () => {
    setValueserie("");
};
const resetgamme = () => {
    setValuegamme("");
};
const resetpantone = () => {
    setValuepantone("");
};
const handleInputChange = (inputValue, actionMeta) => {
  // Si l'utilisateur tape quelque chose, ouvrez le menu
  if (inputValue && inputValue.trim().length > 0) {
    setIsMenuOpen(true);
  } else {
    setIsMenuOpen(false);
  }
};
  return (
    <div>
       <Box >
          <List >
            <ListItem>
              <ListItemIcon>
                <FilterAltIcon  sx={{ fontSize: 40}} />
              </ListItemIcon>
              <ListItemText primary={t('filter.filtre') }  secondary={t('filter.information') }/>
            </ListItem>
            <Divider variant="middle" sx={{  minWidth: 228 }}/>
            <ListItem >
              <FormControl   disabled={true}  sx={{  minWidth: 228 }}>
                <FormLabel disabled={true}  sx={{ fontSize: 14}} id="demo-row-radio-buttons-group-label">{t('filter.type')} :  </FormLabel>
                <Selectreact options={selectOptType} onChange={handleChangeType} isSearchable={false} placeholder={t('filter.selecttype') }>


                       </Selectreact >
              </FormControl>
            </ListItem>          
            <ListItem>
              <FormControl  disabled  sx={{  minWidth: 228  }}>
                <FormLabel sx={{ fontSize: 14}} id="demo-row-radio-buttons-group-label">{t('filter.serie')} : </FormLabel>
                <Selectreact className="drop-down" options={selectOptSerie?.map((selectOptSerie, index) => ({ value: selectOptSerie.value,label: selectOptSerie.label,id: selectOptSerie.value}))}
                    isDisabled={isDisabledSerie} 
                    isSearchable={false}
                    name="serie-select"
                    value={valueserie}
                    onChange={handleChangeSerie}
                    placeholder={t('filter.selectserie') }>
                </Selectreact >
              </FormControl>
            </ListItem>
            {showSelect &&
              <ListItem>
                <FormControl disabled  sx={{  minWidth: 228 }}>
                  <FormLabel sx={{ fontSize: 14}} id="gamme-select-label">{t('filter.gamme')} :   </FormLabel>
                  <Selectreact 
                    className="drop-down"
                    options={selectOptGamme?.map((selectOptGamme, index) => ({value: selectOptGamme.value,label: selectOptGamme.label,id: selectOptGamme.value}))}
                    isDisabled={isDisabledGamme} 
                    isSearchable={false}
                    name="gamme-select"
                    value={valuegamme} 
                    onChange={handleChangeGamme}
                    placeholder={t('filter.selectgamme') }>
                  </Selectreact >
                </FormControl>
              </ListItem>
           }
          <ListItem>
           <FormControl disabled  sx={{ minWidth: 228 }}>
              <FormLabel sx={{ fontSize: 14}} id="demo-row-radio-buttons-group-label">{t('filter.reference')} : </FormLabel>
              <Selectreact
              className="drop-down"
              options={selectOptPantone?.map((selectOptPantone, index) => ({
                value: selectOptPantone.value,
                label: selectOptPantone.label,
                id: selectOptPantone.value
              }))}
              isDisabled={isDisabledPantone}
              openOnClick={false}
              isSearchable={true}
              name="pantone-select"
              value={valuepantone}
              onChange={handleChangePantone}
              placeholder={t('filter.saisirreference')}
              onInputChange={handleInputChange}
              menuIsOpen={isMenuOpen}
              filterOption={(option, inputValue) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
              }
            >
            </Selectreact>
                      
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl  sx={{  minWidth: 228  }} >
              <FormLabel sx={{ fontSize: 14}} id="demo-row-radio-buttons-group-label">{t('filter.quantitefab')} : </FormLabel>
              <OutlinedInput defaultValue={optPoids} size="small" id="outlined-adornment-weight" endAdornment={<InputAdornment position="end">{optUnite}</InputAdornment>}
              onChange={(e) => setOptPoids (e.target.value)}/>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl     sx={{ minWidth: 228 }}>
              <FormLabel  sx={{ fontSize: 14}} id="group-unite">{t('filter.unite')} : </FormLabel>
              <RadioGroup row size="small" aria-labelledby="group-unite" name="radio-buttons-unite" value={optUnite} onChange={(e) => setOptUnite (e.target.value)}>
                <FormControlLabel sx={{ fontSize: 14}} labelPlacement="bottom" size="small" value="kg" control={<Radio />} label={<Typography variant="body2" color="textSecondary">{t('filter.kilo')}</Typography>} />
                <FormControlLabel sx={{ fontSize: 14}} labelPlacement="bottom"  size="small" value="g" control={<Radio />} label={<Typography variant="body2" color="textSecondary">{t('filter.gramme')}</Typography>}    />
              </RadioGroup>
            </FormControl>
          </ListItem>
          <ListItem>
            <ReactToPrint content={() => refprops.current}>
              <PrintContextConsumer>{({ handlePrint }) => (
                <Button style={{ padding: '23px 100px',backgroundColor: "#F4F5F7" }} onClick={handlePrint} variant="contained" >
                  <PrintIcon style={{ color: "#BA0C2F" }}   />
                </Button>
                    )}
              </PrintContextConsumer>
            </ReactToPrint>
          </ListItem>
        </List>
      </Box>
    </div>    
    )
}
export default Selectfilter

