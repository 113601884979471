// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

// start the Stimulus application
import './bootstrap';

import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from "react-i18next";
import './helpers/i18n';

import Dashboard from '../assets/Components/Dashboard.js';

    function App() {
    return ( <div >
        <Dashboard />
     </div>)        
    }
    
    export default App

ReactDOM.render(<App />, document.getElementById('root'));
